import axios from 'axios'
import { toast } from 'react-toastify'

const api = axios.create({
  baseURL: 'https://api-gerador.sunsbrasil.com.br/api/'
})

api.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response parse
api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response === undefined) {
      localStorage.removeItem('persist:sol_brasil')
      const storageSolBrasil = localStorage.getItem('persist:sol_brasil')
      if (storageSolBrasil === null) {
        toast.error('Não foi possível efetuar comunicação com o banco de dados. Contacte o administrador.')
        if (error.response.config.url !== 'auth/login') {
          document.location.reload(true)
        }
      }
    } else {
      if (error.response.status === 401) {
        localStorage.removeItem('persist:sol_brasil')
        const storageSolBrasil = localStorage.getItem('persist:sol_brasil')
        if (storageSolBrasil === null) {
          if (error.response.config.url !== 'auth/login') {
            document.location.reload(true)
          }
        }
      }
    }
    return Promise.reject(error)
  }
)

export default api
